var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section section-secondary jpadding",attrs:{"id":"login"}},[_c('div',{staticClass:"jcard jcard-main jcard-nohover"},[_c('div',{staticClass:"brand mb-3"},[_c('h3',[_vm._v("WATCH MODEL")]),_c('p',{staticClass:"text-center"},[_c('small',[_c('b',[_vm._v(_vm._s(_vm.modelName))]),_vm._v(" "),_c('br'),_vm._v("Update watch model details.")])])]),_c('div',{staticClass:"btn-container-center"},[_c('button',{staticClass:"jbtn jbtn-sm mr-3",class:{ 'jbtn-outline': _vm.language != 'en' },on:{"click":function($event){return _vm.changeLaguage('en')}}},[_vm._v(" EN ")]),_c('button',{staticClass:"jbtn jbtn-sm",class:{ 'jbtn-outline': _vm.language != 'fr' },on:{"click":function($event){return _vm.changeLaguage('fr')}}},[_vm._v(" FR ")])]),_vm._m(0),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.cooperation),expression:"editData.cooperation"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.cooperation) && _vm.bluredCooperation
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.cooperation)},on:{"blur":function($event){_vm.bluredCooperation = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "cooperation", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.limited),expression:"editData.limited"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validNonTxtField(_vm.editData.limited) && _vm.bluredLimited
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.limited)},on:{"blur":function($event){_vm.bluredLimited = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "limited", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.color),expression:"editData.color"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validNonTxtField(_vm.editData.color) && _vm.bluredColor
            },attrs:{"type":"text","placeholder":"#ffffff"},domProps:{"value":(_vm.editData.color)},on:{"blur":function($event){_vm.bluredColor = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "color", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(4),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.text),expression:"editData.text"}],class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.editData.text) && _vm.bluredText
          },attrs:{"type":"text"},domProps:{"value":(_vm.editData.text)},on:{"blur":function($event){_vm.bluredText = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "text", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a short description text. ")])]),_vm._m(5),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.movement),expression:"editData.movement"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.movement) && _vm.bluredMovement
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.movement)},on:{"blur":function($event){_vm.bluredMovement = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "movement", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(7),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.winding),expression:"editData.winding"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.winding) && _vm.bluredWinding
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.winding)},on:{"blur":function($event){_vm.bluredWinding = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "winding", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(8),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.complications),expression:"editData.complications"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.complications) && _vm.bluredComplications
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.complications)},on:{"blur":function($event){_vm.bluredComplications = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "complications", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(9),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.nbOfJewels),expression:"editData.nbOfJewels"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validNonTxtField(_vm.editData.nbOfJewels) && _vm.bluredNbOfJewels
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.nbOfJewels)},on:{"blur":function($event){_vm.bluredNbOfJewels = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "nbOfJewels", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(10),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.powerReserve),expression:"editData.powerReserve"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.powerReserve) && _vm.bluredPowerReserve
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.powerReserve)},on:{"blur":function($event){_vm.bluredPowerReserve = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "powerReserve", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(11),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.diameter),expression:"editData.diameter"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.diameter) && _vm.bluredDiameter
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.diameter)},on:{"blur":function($event){_vm.bluredDiameter = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "diameter", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(12),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.thickness),expression:"editData.thickness"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.thickness) && _vm.bluredThickness
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.thickness)},on:{"blur":function($event){_vm.bluredThickness = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "thickness", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(13),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.finishingMovement),expression:"editData.finishingMovement"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.finishingMovement) && _vm.bluredFinishingMovement
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.finishingMovement)},on:{"blur":function($event){_vm.bluredFinishingMovement = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "finishingMovement", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_vm._m(14),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(15),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.material),expression:"editData.material"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.material) && _vm.bluredMaterial
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.material)},on:{"blur":function($event){_vm.bluredMaterial = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "material", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(16),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.appliques),expression:"editData.appliques"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.appliques) && _vm.bluredAppliques
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.appliques)},on:{"blur":function($event){_vm.bluredAppliques = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "appliques", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(17),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.signaturePlate),expression:"editData.signaturePlate"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.signaturePlate) && _vm.bluredSignaturePlate
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.signaturePlate)},on:{"blur":function($event){_vm.bluredSignaturePlate = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "signaturePlate", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(18),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.indexRing),expression:"editData.indexRing"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.indexRing) && _vm.bluredIndexRing
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.indexRing)},on:{"blur":function($event){_vm.bluredIndexRing = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "indexRing", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(19),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.finishingDial),expression:"editData.finishingDial"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.finishingDial) && _vm.bluredFinishingDial
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.finishingDial)},on:{"blur":function($event){_vm.bluredFinishingDial = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "finishingDial", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_vm._m(20),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(21),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.bezel),expression:"editData.bezel"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.bezel) && _vm.bluredBezel
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.bezel)},on:{"blur":function($event){_vm.bluredBezel = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "bezel", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(22),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.lugs),expression:"editData.lugs"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.lugs) && _vm.bluredLugs
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.lugs)},on:{"blur":function($event){_vm.bluredLugs = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "lugs", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(23),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.middleCase),expression:"editData.middleCase"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.middleCase) && _vm.bluredMiddleCase
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.middleCase)},on:{"blur":function($event){_vm.bluredMiddleCase = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "middleCase", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(24),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.container),expression:"editData.container"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.container) && _vm.bluredContainer
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.container)},on:{"blur":function($event){_vm.bluredContainer = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "container", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(25),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.caseBack),expression:"editData.caseBack"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.caseBack) && _vm.bluredCaseBack
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.caseBack)},on:{"blur":function($event){_vm.bluredCaseBack = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "caseBack", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])]),_c('div',{staticClass:"col-md form-group"},[_vm._m(26),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.crown),expression:"editData.crown"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.crown) && _vm.bluredCrown
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.crown)},on:{"blur":function($event){_vm.bluredCrown = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "crown", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md form-group"},[_vm._m(27),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.finishingCase),expression:"editData.finishingCase"}],class:{
              'form-control': true,
              'is-invalid': !_vm.validInput(_vm.editData.finishingCase) && _vm.bluredFinishingCase
            },attrs:{"type":"text"},domProps:{"value":(_vm.editData.finishingCase)},on:{"blur":function($event){_vm.bluredFinishingCase = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "finishingCase", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid input. ")])])]),(_vm.showInputError)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fad fa-exclamation-circle"}),_vm._v(" "+_vm._s(_vm.validationMsg)+" ")]):_vm._e(),_c('div',{staticClass:"btn-container text-center mt-5"},[_c('button',{staticClass:"jbtn jbtn-blue",attrs:{"value":"Add"},on:{"click":_vm.updateDoc}},[_c('i',{staticClass:"fad fa-check"}),_vm._v(" Save ")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title mt-3"},[_c('h3',[_c('small',[_c('i',{staticClass:"fal fa-watch"})]),_vm._v(" Overview ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Cooperation")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Limited Edition")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Product Color")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"text"}},[_c('i',{staticClass:"fal fa-comment-alt"}),_vm._v(" Text")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title mt-3"},[_c('h3',[_c('small',[_c('i',{staticClass:"fal fa-cog"})]),_vm._v(" Movement ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Movement")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Winding")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Complications")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-gem"}),_vm._v(" Number of Jewels")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Power Reserve")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Diameter")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Thickness")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Finishing Movement")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title mt-3"},[_c('h3',[_c('small',[_c('i',{staticClass:"fal fa-cog"})]),_vm._v(" Dial ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Material")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Appliques")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Signature Plate")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Index Ring")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Finishing Dial")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title mt-3"},[_c('h3',[_c('small',[_c('i',{staticClass:"fal fa-cog"})]),_vm._v(" Case ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Bezel")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Lugs")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Middle Case")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Container")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Case Back")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Crown")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Finishing Case")])
}]

export { render, staticRenderFns }