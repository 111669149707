<template>
  <div>
    <section id="login" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div class="brand mb-3">
          <h3>WATCH MODEL</h3>
          <p class="text-center">
            <small
              ><b>{{ modelName }}</b> <br />Update watch model details.</small
            >
          </p>
        </div>

        <!-- LANGUAGE SELECT -->
        <div class="btn-container-center">
          <button
            class="jbtn jbtn-sm mr-3"
            @click="changeLaguage('en')"
            :class="{ 'jbtn-outline': language != 'en' }"
          >
            EN
          </button>

          <button
            class="jbtn jbtn-sm"
            @click="changeLaguage('fr')"
            :class="{ 'jbtn-outline': language != 'fr' }"
          >
            FR
          </button>
        </div>

        <!-- OVERVIEW -->
        <div class="card-title mt-3">
          <h3>
            <small><i class="fal fa-watch"></i></small> Overview
          </h3>
        </div>
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Cooperation</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.cooperation) && bluredCooperation
              }"
              v-on:blur="bluredCooperation = true"
              v-model="editData.cooperation"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Limited Edition</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validNonTxtField(editData.limited) && bluredLimited
              }"
              v-on:blur="bluredLimited = true"
              v-model="editData.limited"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <div class="form-group">
            <label for="name"> <i class="fal fa-tag"></i> Product Color</label>
            <input
              type="text"
              placeholder="#ffffff"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validNonTxtField(editData.color) && bluredColor
              }"
              v-on:blur="bluredColor = true"
              v-model="editData.color"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>

        <!-- text box -->
        <div class="form-group">
          <label for="text"><i class="fal fa-comment-alt"></i> Text</label>
          <textarea
            type="text"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(editData.text) && bluredText
            }"
            v-on:blur="bluredText = true"
            v-model="editData.text"
          />
          <div class="invalid-feedback">
            Please enter a short description text.
          </div>
        </div>

        <!-- MOVEMENT -->
        <div class="card-title mt-3">
          <h3>
            <small><i class="fal fa-cog"></i></small> Movement
          </h3>
        </div>
        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Movement</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.movement) && bluredMovement
              }"
              v-on:blur="bluredMovement = true"
              v-model="editData.movement"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Winding</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.winding) && bluredWinding
              }"
              v-on:blur="bluredWinding = true"
              v-model="editData.winding"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Complications</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.complications) && bluredComplications
              }"
              v-on:blur="bluredComplications = true"
              v-model="editData.complications"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-gem"></i> Number of Jewels</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validNonTxtField(editData.nbOfJewels) && bluredNbOfJewels
              }"
              v-on:blur="bluredNbOfJewels = true"
              v-model="editData.nbOfJewels"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Power Reserve</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.powerReserve) && bluredPowerReserve
              }"
              v-on:blur="bluredPowerReserve = true"
              v-model="editData.powerReserve"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Diameter</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.diameter) && bluredDiameter
              }"
              v-on:blur="bluredDiameter = true"
              v-model="editData.diameter"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Thickness</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.thickness) && bluredThickness
              }"
              v-on:blur="bluredThickness = true"
              v-model="editData.thickness"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Finishing Movement</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.finishingMovement) && bluredFinishingMovement
              }"
              v-on:blur="bluredFinishingMovement = true"
              v-model="editData.finishingMovement"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- DIAL -->
        <div class="card-title mt-3">
          <h3>
            <small><i class="fal fa-cog"></i></small> Dial
          </h3>
        </div>
        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Material</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.material) && bluredMaterial
              }"
              v-on:blur="bluredMaterial = true"
              v-model="editData.material"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Appliques</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.appliques) && bluredAppliques
              }"
              v-on:blur="bluredAppliques = true"
              v-model="editData.appliques"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Signature Plate</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.signaturePlate) && bluredSignaturePlate
              }"
              v-on:blur="bluredSignaturePlate = true"
              v-model="editData.signaturePlate"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Index Ring</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.indexRing) && bluredIndexRing
              }"
              v-on:blur="bluredIndexRing = true"
              v-model="editData.indexRing"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Finishing Dial</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.finishingDial) && bluredFinishingDial
              }"
              v-on:blur="bluredFinishingDial = true"
              v-model="editData.finishingDial"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- CASE -->
        <div class="card-title mt-3">
          <h3>
            <small><i class="fal fa-cog"></i></small> Case
          </h3>
        </div>
        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Bezel</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.bezel) && bluredBezel
              }"
              v-on:blur="bluredBezel = true"
              v-model="editData.bezel"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Lugs</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.lugs) && bluredLugs
              }"
              v-on:blur="bluredLugs = true"
              v-model="editData.lugs"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Middle Case</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.middleCase) && bluredMiddleCase
              }"
              v-on:blur="bluredMiddleCase = true"
              v-model="editData.middleCase"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Container</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.container) && bluredContainer
              }"
              v-on:blur="bluredContainer = true"
              v-model="editData.container"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Case Back</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.caseBack) && bluredCaseBack
              }"
              v-on:blur="bluredCaseBack = true"
              v-model="editData.caseBack"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Crown</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.crown) && bluredCrown
              }"
              v-on:blur="bluredCrown = true"
              v-model="editData.crown"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- row -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="name"> <i class="fal fa-tag"></i> Finishing Case</label>
            <input
              type="text"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.finishingCase) && bluredFinishingCase
              }"
              v-on:blur="bluredFinishingCase = true"
              v-model="editData.finishingCase"
            />
            <div class="invalid-feedback">
              Please enter a valid input.
            </div>
          </div>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>

        <!-- save btn -->
        <div class="btn-container text-center mt-5 ">
          <button class="jbtn jbtn-blue" value="Add" @click="updateDoc">
            <i class="fad fa-check"></i> Save
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "CreateWatchModel",
  data() {
    return {
      //display
      language: "en",

      //form error alert
      showInputError: false,
      validationMsg: "",

      //edit news data form
      editMode: false,
      valid: false,

      bluredLimited: false,
      bluredCooperation: false,
      bluredText: false,
      bluredColor: false,
      bluredMovement: false,
      bluredWinding: false,
      bluredComplications: false,
      bluredNbOfJewels: false,
      bluredPowerReserve: false,
      bluredDiameter: false,
      bluredThickness: false,
      bluredFinishingMovement: false,
      bluredMaterial: false,
      bluredAppliques: false,
      bluredSignaturePlate: false,
      bluredIndexRing: false,
      bluredFinishingDial: false,
      bluredBezel: false,
      bluredLugs: false,
      bluredMiddleCase: false,
      bluredContainer: false,
      bluredCaseBack: false,
      bluredCrown: false,
      bluredFinishingCase: false,

      //model
      modelId: "",
      modelName: "",
      editData: {
        // name: "",
        // active: true,
        // price: 0,
        // releaseDate: 2022,
        text: "",
        color: "",
        cooperation: "",
        limited: 8,
        movement: "",
        complications: "",
        dial: "",
        case: "",
        tourbillon: "",
        winding: "",
        nbOfJewels: "",
        frequency: "",
        powerReserve: "",
        diameter: "",
        thickness: "",
        finishingMovement: "",
        material: "",
        appliques: "",
        signaturePlate: "",
        indexRing: "",
        finishingDial: "",
        bezel: "",
        lugs: "",
        middleCase: "",
        container: "",
        caseBack: "",
        crown: "",
        finishingCase: ""
      }
    };
  },
  methods: {
    getWatchModel() {
      var docRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc(this.modelId);
      docRef.onSnapshot(doc => {
        if (doc.exists) {
          this.modelName = doc.data().name;
          //this.$store.commit("setNews", doc.data());
          if (this.language === "en" && doc.data().en) this.editData = doc.data().en;
          else if (this.language === "fr" && doc.data().fr) this.editData = doc.data().fr;
        } else {
          // doc.data() will be undefined in this case
          console.log("Model document not found. No such modelId!");
          //disable editMode and load create mode
          this.editMode = false;
        }
      });
    },
    changeLaguage(lang) {
      if (lang === "en") this.language = "en";
      else if (lang === "fr") this.language = "fr";

      this.getWatchModel();
    },
    // save watch model data to db
    updateDoc() {
      if (!this.validate()) return;

      var editDataLanguage;

      if (this.language === "en") {
        editDataLanguage = { en: this.editData };
      } else if (this.language === "fr") {
        editDataLanguage = { fr: this.editData };
      }

      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc(this.modelId);
      else docRef = db.collection(C.COLLECTION.WATCH_CATALOG).doc();
      docRef
        .set(editDataLanguage, { merge: true })
        .then(() => {
          if (this.editMode) toast.success("Watch model details edited.");
          else toast.success("Watch model created.");
        })
        .catch(error => {
          if (this.editMode) toast.error("Couldn't edit watch model details. " + error.message);
          else toast.error("Couldn't create watch model. " + error.message);
        });
    },
    validate() {
      //Check input fields
      this.bluredText = true;
      if (!this.validInput(this.editData.text)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validNonTxtField(input) {
      return input != null && input != "";
    }
  },

  created() {
    //init

    //create model: if no id is passed. edit article: get id and load existing model
    //get doc id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.modelId = this.$route.params.id;
      this.editMode = true;
    }

    //load news article in edit mode
    if (this.editMode) {
      this.loadModel();
    }
  }
};
</script>

<style lang="scss" scoped></style>
